import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"
import PageTitle from "./page-title"

const SimpleInnerPageW = styled.div``
const SimpleInnerPageIW = styled.div``
const ContentW = styled.div``

const SimpleInnerPage = ({ title, subTitle, date, icon, children }) => {
  return (
    <SimpleInnerPageW>
      <PageTitle title={title} subTitle={subTitle} icon={icon} date={date} />
      <SimpleInnerPageIW>
        <Container>
          <ContentW>{children}</ContentW>
        </Container>
      </SimpleInnerPageIW>
    </SimpleInnerPageW>
  )
}

export default SimpleInnerPage

import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleInnerPage from "../components/simple-inner-page"
import contactSupport from "../images/contact-support.svg"
import { breakpoints } from "../components/variables"

const Paragraph = styled.p`
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const IndexPage = () => (
  <Layout>
    <SEO title="Support" />
    <SimpleInnerPage
      title="Support"
      subTitle="support@datamap.tools"
      icon={contactSupport}
    >
      <Paragraph>
        Our software specialists offer ongoing support in person, over the
        phone, via email, or over a remote desktop connection. Our experts
        troubleshoot errors, install service packs and perform annual system
        upgrades so you can continue doing what you do best: run your business.
      </Paragraph>
      <Paragraph>
        Our dedicated support team is here when you need them. When you call the
        DataMap line during business hours, a live, California based consultant
        will answer the phone. Due to our large install base, our consultants
        have tackled countless issues for our clients and always go above and
        beyond to solve your problem. Because the majority of our consulting
        team is in the same office, we collaborate to solve even the toughest
        issues to help your business succeed. Whether you need training for a
        new member of the team, are looking for a quicker way to get product to
        your customer or want to add alerts when the price of an item differs
        from the standard cost, DataMap can make it happen!
      </Paragraph>
    </SimpleInnerPage>
  </Layout>
)

export default IndexPage
